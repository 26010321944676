import {useCookies} from "react-cookie/cjs";
import {useNavigate} from "react-router-dom";
import {useCallback} from "react";

export const useFetchUserData = () => {
    const [cookies, setCookie, removeCookie] = useCookies(
        ['Authorization', 'firstName', 'lastName']
    );

    const fetchUserData = async (setUserData: Function) => {
        try {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': cookies.Authorization
                },
            }
            const userResponse = await fetch(process.env.REACT_APP_BASE_URL + "get_user_info", requestOptions)
            const data = await userResponse.json()
            if (data.success) {
                if (setUserData) {
                    setUserData((prevData: any) => ({
                        ...prevData,
                        user_id: data.user.user_id,
                        email: data.user.email,
                        avatar: data.user.avatar,
                        firstname: data.user.firstname,
                        lastname: data.user.lastname,
                        language: data.user.language,
                        phone: data.user.phone,
                        email_verified: data.user.email_verified,
                        plan_expire: data.user.plan_expire,
                        plan_level: data.user.plan_level,
                        region: data.region
                    }));
                }
            } else {
                removeCookie('Authorization', { path: '/' })
                removeCookie('firstName', { path: '/' })
                removeCookie('lastName', { path: '/' })
            }
        } catch (error) {
            removeCookie('Authorization', { path: '/' })
            removeCookie('firstName', { path: '/' })
            removeCookie('lastName', { path: '/' })
        }
    }

    return fetchUserData;
}

export const useLogout = () => {
    const [, , removeCookie] = useCookies(['Authorization', 'firstName', 'lastName']);
    const navigate = useNavigate();

    const logout = useCallback(() => {
        console.log("Sign out");
        removeCookie('Authorization', { path: '/' });
        removeCookie('firstName', { path: '/' });
        removeCookie('lastName', { path: '/' });
        setTimeout(() => {
            navigate("/");
            window.location.reload(); // Force page reload
        }, 300);
    }, [removeCookie, navigate]);

    return { logout };
};