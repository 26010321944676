import React, {useEffect, useState} from 'react';
import {Link as RouterLink, useNavigate} from 'react-router-dom'
import {useCookies} from "react-cookie";
import {useTranslation} from "react-i18next";
import {
    Box, Flex, HStack, Image, Link, Button, Menu, MenuButton, MenuList, MenuItem,
    Avatar, Text, MenuOptionGroup, MenuItemOption, MenuDivider, MenuGroup
} from '@chakra-ui/react'
import { TriangleDownIcon } from '@chakra-ui/icons'
import logoImage from '../../media/TCF_logo.svg';
import logoutIcon from '../../media/Logout.svg';
import userAvatar from '../../media/User_default_avatar.png';
import {useFetchUserData, useLogout} from "../publicMethods/publicFunctionMethods";

const Header: React.FC = ({...props}) => {
    const navigate = useNavigate();

    const languageMapping: { [key: string]: string } = {
        "en": 'English',
        "fr": 'Français',
        "zh": "中文",
        "ja": "日本語",
        "zh-CHS": '简体中文',
        "zh-CHZ": '繁体中文'
    }

    const [cookies, setCookie, removeCookie] = useCookies(
        ['Authorization', 'firstName', 'lastName', 'avatar']
    );

    const { i18n } = useTranslation();

    const { t } = useTranslation();

    const isLogin = (cookies.Authorization && cookies.Authorization!=='undefined');

    const fetchUserData = useFetchUserData();

    const { logout } = useLogout();

    const [displayLanguage, setDisplayLanguage] = useState(languageMapping[i18n.language]);
    const [userData, setUserData] = useState({
        user_id: undefined,
        email: undefined,
        avatar: undefined,
        firstname: undefined,
        lastname: undefined,
        language: undefined,
        phone: undefined,
        email_verified: undefined,
        plan_expire: undefined,
        plan_level: undefined,
        region: undefined
    });

    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
        localStorage.setItem('userLanguage', lng);
        setDisplayLanguage(languageMapping[lng])

    };

    const handleNavigation = (path: string) => {
        navigate(path);
        window.location.reload(); // Force page reload
    }

    useEffect(() => {
        const savedLanguage = localStorage.getItem('userLanguage');
        if (savedLanguage) {
            i18n.changeLanguage(savedLanguage);
        }
    }, [i18n]);

    useEffect(() => {
        // request server to validate cookie and get user info
        if (cookies.firstName === undefined || cookies.firstName === null) {
            fetchUserData(setUserData);
        }
    }, [cookies.Authorization, cookies.firstName]);

    return (
        <Box as="nav" px="4" py="2" position="fixed" top="0" width="100%" zIndex="1" color="white"
             background="linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(88, 88, 88, 0.6) 60%, rgba(102, 102, 102, 0) 100%);">
            <Flex as="header" align="center" justify="space-between"
                  sx={{
                      '& *': {
                          fontWeight: 'bold !important'
                      }
                  }}>
                <HStack as="nav" spacing="4">
                    <Box height="40px" marginRight="2vw">
                        <Link as={RouterLink} onClick={() => handleNavigation("/")}>
                            <Image src={logoImage} alt="TFC CANADA" objectFit="contain" boxSize="66px" />
                        </Link>
                    </Box>
                    <Link margin="0 3vw" as={RouterLink} onClick={() => handleNavigation("/")}>{t('header.homepage')}</Link>
                    <Menu>
                        <MenuButton margin="0 3vw" as={Button} variant="link" leftIcon={<TriangleDownIcon />}>
                            {t('header.objective_testing')}
                        </MenuButton>
                        <MenuList>
                            <MenuItem as={RouterLink} color="black" onClick={() => handleNavigation("/Comprehension?type=1")}>
                                {t('header.listening_comprehension')}</MenuItem>
                            <MenuItem as={RouterLink} color="black" onClick={() => handleNavigation("/Comprehension?type=2")}>
                                {t('header.reading_comprehension')}</MenuItem>
                        </MenuList>
                    </Menu>

                    <Menu>
                        <MenuButton margin="0 3vw" as={Button} variant="link" leftIcon={<TriangleDownIcon />}>
                            {t('header.subjective_testing')}
                        </MenuButton>
                        <MenuList>
                            <MenuGroup color="black" title={t('header.speaking')}>
                                <MenuItem as={RouterLink} color="black" onClick={() => handleNavigation("/Subjective?type=2&task=2")}>
                                    &nbsp;&nbsp;{t('header.task')}&nbsp;2</MenuItem>
                                <MenuItem as={RouterLink} color="black" onClick={() => handleNavigation("/Subjective?type=2&task=3")}>
                                    &nbsp;&nbsp;{t('header.task')}&nbsp;3</MenuItem>
                            </MenuGroup >
                            <MenuDivider />
                            <MenuGroup color="black" title={t('header.writing')}>
                                <MenuItem as={RouterLink} color="black" onClick={() => handleNavigation("/Subjective?type=1&task=1")}>
                                    &nbsp;&nbsp;{t('header.task')}&nbsp;1</MenuItem>
                                <MenuItem as={RouterLink} color="black" onClick={() => handleNavigation("/Subjective?type=1&task=2")}>
                                    &nbsp;&nbsp;{t('header.task')}&nbsp;2</MenuItem>
                                <MenuItem as={RouterLink} color="black" onClick={() => handleNavigation("/Subjective?type=1&task=3")}>
                                    &nbsp;&nbsp;{t('header.task')}&nbsp;3</MenuItem>
                            </MenuGroup >
                        </MenuList>
                    </Menu>

                    <Link margin="0 3vw" as={RouterLink} onClick={() => handleNavigation("/subscription")}>{t('header.Subscription')}</Link>
                </HStack>
                <Flex alignItems="center">
                    <Menu>
                        <MenuButton as={Button} color="white" border="1px" backgroundColor="transparent" marginRight="8vw">
                            {displayLanguage}
                        </MenuButton>
                        <MenuList color="black">
                            {/*<MenuItem onClick={() => changeLanguage('fr')}>Français</MenuItem>*/}
                            <MenuItem onClick={() => changeLanguage('en')}>English</MenuItem>
                            <MenuItem onClick={() => changeLanguage('zh-CHS')}>简体中文</MenuItem>
                            {/*<MenuItem onClick={() => changeLanguage('zh-CHZ')}>繁体中文</MenuItem>*/}
                        </MenuList>
                    </Menu>
                    {isLogin ?
                        (<Menu>
                            <MenuButton as={Button} variant="link">
                                <HStack>
                                    <Text>{cookies.firstName + " " + cookies.lastName}</Text>
                                    <Avatar size="sm" name={cookies.firstName + " " + cookies.lastName} />
                                </HStack>
                            </MenuButton>
                            <MenuList>
                                <MenuItem as={RouterLink} color="black" onClick={() => handleNavigation("/profile")}>
                                    <Avatar
                                        size="xs"
                                        rounded="full"
                                        src={userAvatar}
                                    />&nbsp;&nbsp;
                                    {t('header.my_profile')}
                                </MenuItem>
                                <MenuItem onClick={logout} color="black">
                                    <Avatar
                                        size="xs"
                                        rounded="full"
                                        src={logoutIcon}
                                    />&nbsp;&nbsp;
                                    {t('login.logout')}
                                </MenuItem>
                            </MenuList>
                        </Menu>)
                        : (<Button as={RouterLink} to="/login" colorScheme={"blue"} color="white">
                                {t('login.login')}
                            </Button>)
                    }
                </Flex>
            </Flex>
        </Box>
    )
}

export default Header;