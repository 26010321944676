import React, { useCallback, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Button, Flex, Text, VStack, Image, Grid, Skeleton, useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ChevronLeftIcon } from '@chakra-ui/icons';

const WechatPayment: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const plan_level = queryParams.get('plan_level') || '1';
    const [cookies] = useCookies(['Authorization']);
    const { t } = useTranslation();
    const toast = useToast();

    const [qrCode, setQrCode] = useState('');
    const [amount, setAmount] = useState(0);
    const [countdown, setCountdown] = useState(600); // 10分钟倒计时
    const [transactionId, setTransactionId] = useState('');
    const [isPaymentConfirmed, setIsPaymentConfirmed] = useState(false);
    const [isQRCodeLoading, setIsQRCodeLoading] = useState(true);

    const fetchClientSecret = useCallback(() => {
        return fetch(process.env.REACT_APP_BASE_URL + "place_order", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': cookies.Authorization
            },
            body: JSON.stringify({
                plan_level: plan_level,
                wechat_pay: true
            }),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.success) {
                    setQrCode(data.picture_url);
                    setAmount(data.cny_amount);
                    setTransactionId(data.transaction_id);
                }
            });
    }, [plan_level, cookies.Authorization]);

    const checkPaymentStatus = useCallback(() => {
        if (transactionId) {
            fetch(process.env.REACT_APP_BASE_URL + "check_payment_status", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': cookies.Authorization
                },
                body: JSON.stringify({
                    transaction_id: transactionId
                }),
            })
                .then((res) => res.json())
                .then((data) => {
                    if (data.success && data.success) {
                        setIsPaymentConfirmed(true);
                        toast({
                            title: t('checkout.payment_completed'),
                            status: 'info',
                            duration: 3000,
                            isClosable: true,
                            position: 'top'
                        });
                        navigate('/ReturnResult?success=1');
                    }
                    else {
                        setIsPaymentConfirmed(false);

                    }
                });
        }
    }, [transactionId, cookies.Authorization, navigate, t, toast]);

    useEffect(() => {
        fetchClientSecret();
    }, [fetchClientSecret]);

    // useEffect(() => {
    //     const timer = setInterval(() => {
    //         setCountdown((prev) => {
    //             if (prev <= 0) {
    //                 clearInterval(timer);
    //                 return 0;
    //             }
    //             return prev - 1;
    //         });
    //     }, 1000);

    //     return () => clearInterval(timer);
    // }, []);

    useEffect(() => {
        const checkInterval = setInterval(() => {
            checkPaymentStatus();
        }, 3000);

        return () => clearInterval(checkInterval);
    }, [checkPaymentStatus]);

    const formatTime = (seconds: number) => {
        const mins = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${mins}min ${secs}sec`;
    };

    return (
        <Box height="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <Box
                bg="white"
                p={8}
                rounded="md"
                // shadow="md"
                w="80vw"
                maxW="600px"
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Flex w="100%" alignItems="center" mb={6}>
                    <Button
                        leftIcon={<ChevronLeftIcon />}
                        variant="ghost"
                        onClick={() => navigate(-1)}
                    >
                        {t('common.back')}
                    </Button>
                </Flex>

                <Text fontSize="3xl" fontWeight="bold" mb={2}>
                    ¥{amount.toFixed(2)}
                </Text>
                <Text fontSize="md" color="gray.500" mb={6}>
                    (${(amount / 7.2).toFixed(2)})
                </Text>

                {/* <Text color={countdown > 60 ? "green.500" : "red.500"} mb={4}>
                    {t('checkout.order_available_within')}: {formatTime(countdown)}
                </Text> */}

                {countdown > 0 ? (
                    <>
                        <Box mb={4}>
                            <Flex w="100%" justify="flex-start" mb={4}>
                                <Image
                                    src="/wechat_logo.png"
                                    alt="WeChat Pay Logo"
                                    width="120px"
                                    height="auto"
                                />
                            </Flex>
                            <VStack spacing={4}>
                                <Skeleton isLoaded={!isQRCodeLoading} width="200px" height="200px">
                                    <Image
                                        src={qrCode}
                                        alt="WeChat QR Code"
                                        width="200px"
                                        height="200px"
                                        onLoad={() => setIsQRCodeLoading(false)}
                                    />
                                </Skeleton>
                            </VStack>
                        </Box>
                        <Text fontSize="sm" color="gray.600" mb={4}>
                            {t('checkout.scan_wechat_to_pay')}
                        </Text>
                    </>
                ) : (
                    <Text color="red.500" fontSize="lg">
                        {t('checkout.order_expired')}
                    </Text>
                )}
            </Box>
        </Box>
    );
};

export default WechatPayment; 