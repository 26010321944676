import React, {createContext, Dispatch, SetStateAction, useContext, useState} from 'react';
import {Content} from "../../typeDefinations/itemDefinitions";

export const ContentContext = createContext<{
    contentList: Content[];
    setContentList: Dispatch<SetStateAction<Content[]>>;
}>({
    contentList: [],
    setContentList: () => {}
});

export const ContentProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [contentList, setContentList] = useState<Content[]>([]);

    return (
        <ContentContext.Provider value={{ contentList: contentList, setContentList }}>
            {children}
        </ContentContext.Provider>
    );
};